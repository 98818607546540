<template>
  <v-list class="the-navigation__list" dark dense>
    <v-list-item class="py-2" dark>
      <v-list-item-content
        dark
        class="the-navigation__org_select"
        :class="{ _mini: isNavDrawerMiniVariant }"
      >
        <v-select
          v-model="activeOrganisation"
          @input="setActiveOrganisation"
          :items="organisations"
          item-text="name"
          :menu-props="{ bottom: true, offsetY: true }"
          item-value="id"
          :disabled="isNavDrawerMiniVariant"
          color="darken-3"
          class="myOrgSelect"
          hide-details
          filled
          dark
          dense
        >
          <template v-slot:item="{ item }">
            <span class="org_select" dark>
              <img
                :src="item.logo"
                alt=""
                class="the-navigation__img_logo"
                v-if="item.logo != null"
              />
              <v-icon
                v-else-if="item.id == 0"
                class="the-navigation__img_logo"
                color="orange"
                medium
              >
                mdi-account-box
              </v-icon>
              <avatar
                v-else
                class="the-navigation__img_logo"
                :rounded="false"
                :initials="initials(item.name)"
                :username="item.name"
                :size="24"
                :customStyle="{
                  display: 'inline-block',
                  borderRadius: '4px',
                  fontSize: '11px',
                }"
              />
              <span class="the-navigation__item_name"> {{ item.name }} </span>
              <v-icon
                v-if="item.id == organisationId"
                color="orange"
                style="float: right; margin-top: 4px"
                dark
                small
              >
                mdi-checkbox-marked-circle
              </v-icon>
            </span>
          </template>
          <template v-slot:selection="{ item, index }">
            <span class="org_select" dark>
              <img
                :src="item.logo"
                alt=""
                class="the-navigation__img_logo"
                v-if="item.logo != null"
              />
              <v-icon
                v-else-if="item.id == 0"
                class="the-navigation__img_logo"
                color="orange"
                medium
              >
                mdi-account-box
              </v-icon>
              <avatar
                v-else
                class="the-navigation__img_logo"
                :rounded="false"
                :initials="initials(item.name)"
                :username="item.name"
                :size="24"
                :customStyle="{
                  display: 'inline-block',
                  borderRadius: '4px',
                  fontSize: '11px',
                }"
              />

              <span class="the-navigation__item_name"> {{ item.name }} </span>
            </span>
          </template>

          <template v-slot:append-item>
            
            <v-divider class="mb-2"></v-divider>
            <v-list-item dark @click="createOrganisation">
              <v-list-item-icon>
                <v-icon dark> mdi-plus </v-icon>
              </v-list-item-icon>
              <v-list-item-content dark> Add Organisation </v-list-item-content>
            </v-list-item>
            
          </template>
        </v-select>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="orgSettingsAvailable"
      link
      :to="{ name: 'organisation_main', params: { id: this.organisationId } }"
      class="the-navigation__list-item"
      active-class="_active"
    >
      <v-list-item-icon :class="{ 'mr-3': !isNavDrawerMiniVariant }">
        <v-icon class="the-navigation__nav-icon">settings </v-icon>
      </v-list-item-icon>

      <v-list-item-content class="the-navigation__nav-content">
        Company Settings
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list-item
      link
      to="/"
      class="the-navigation__list-item"
      active-class="_active"
    >
      <v-list-item-icon :class="{ 'mr-3': !isNavDrawerMiniVariant }">
        <the-icon icon="home" class="the-navigation__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation__nav-content">
        Dashboard
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="the-navigation__list-item" active-class="_active">
      <v-list-item-icon :class="{ 'mr-3': !isNavDrawerMiniVariant }">
        <the-icon icon="fill" class="the-navigation__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation__nav-content white--text">
        Your Projects
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text v-if="objMaxSites < 1000">
          {{ objSites }}/{{ objMaxSites }}
        </v-list-item-action-text>
        <v-list-item-action-text v-else> Unlimited </v-list-item-action-text>
      </v-list-item-action>
    </v-list-item>

    <v-list-group
      v-for="project in projectsArray"
      :key="project.id"
      v-model="listGroups[project.id]"
      class="the-navigation__projects"
      :class="{
        _active: isProjectActive(project),
        _small: isNavDrawerMiniVariant,
        'ml-3': !isNavDrawerMiniVariant,
      }"
      :prepend-icon="!isNavDrawerMiniVariant ? 'mdi-menu-right' : ''"
      sub-group
      eager
    >
      <template v-slot:activator>
        <v-list-item
          @click.stop="openProject(project)"
          inactive
          class="the-navigation__head"
        >
          <v-list-item-icon class="the-navigation__project-icon">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <img v-on="on" v-if="project.image" :src="project.image" />
                <v-icon v-on="on" v-else :color="project.iconColor"
                  >mdi-{{ project.icon }}</v-icon
                >
              </template>
              <span> {{ project.name }}</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content class="the-navigation__project_title_content">
            <v-list-item-title>
              <span class="the-navigation__project_title">
                {{ project.name }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="!siteLimitReached"
                v-on="on"
                right
                class="the-navigation__add_site_to_project_icon"
                medium
                @click.stop="createSite(project.id)"
              >
                mdi-plus
              </v-icon>
            </template>
            <span> Create Site in Project </span>
          </v-tooltip>
        </v-list-item>
      </template>

      <draggable
        v-model="project['allObjects']"
        group="sites"
        @change="handleChangePosition($event, project['allObjects'], project)"
      >
        <div v-if="project['allObjects'].length == 0">
          <v-list-item class="the-navigation__project">
            <v-list-item-content>
              <v-list-item-title
                class="the-navigation__sub-list-item-name ml-1"
              >
                I'm empty. Create a site.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <div
          v-for="site in filteredObjects(project['allObjects'])"
          :key="project.id + 'obj' + site.id"
          @click.stop="moveToSite(site)"
          class="the-navigation__project"
          :class="{
            _plugin: site.kind == 'plugin',
            _active: site.id == selectedSite,
          }"
          v-else
        >
          <span class="the-navigation__dot" v-if="site.kind != 'plugin'" />
          <span class="" v-else>
            <img
              class="the-navigation__plugin_img"
              v-if="site.image"
              :src="site.image"
            />
          </span>
          <div class="the-navigation__project_sites_list">
            <div class="the-navigation__sub-list-item-name">
              {{ site.site_name }}
            </div>
          </div>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                @click.stop="openSiteUrl(site)"
                text
                v-on="on"
                icon
                x-small
                class="the-navigation__sub-arrow"
              >
                <the-icon icon="arrow-up-and-right" />
              </v-btn>
            </template>
            <span> Open Site </span>
          </v-tooltip>
        </div>
      </draggable>
    </v-list-group>

    <div class="navigation-sites" :class="{ _small: isNavDrawerMiniVariant }">
      <draggable
        v-model="sitesArray"
        group="sites"
        @change="handleChangePosition($event, sitesArray, null)"
      >
        <div
          v-for="site in sitesArray"
          :key="'simple_obj' + site.id"
          @click.stop="moveToSite(site)"
          class="navigation-sites__list-item"
          active-class="_active"
          :class="{
            _plugin: site.kind == 'plugin',
            _active: site.id == selectedSite,
          }"
        >
          <span class="navigation-sites__dot" v-if="site.kind != 'plugin'" />
          <span class="" v-else>
            <img
              class="navigation-sites__plugin_img"
              v-if="site.image"
              :src="site.image"
            />
          </span>
          <div class="navigation-sites__nav-content">
            {{ site.site_name }}
          </div>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                @click.stop="openSiteUrl(site)"
                text
                v-on="on"
                icon
                x-small
                class="the-navigation__sub-arrow"
              >
                <the-icon icon="arrow-up-and-right" />
              </v-btn>
            </template>
            <span> Open Site </span>
          </v-tooltip>
        </div>
      </draggable>
    </div>

    <v-divider class="mt-2" />
  </v-list>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Avatar from "vue-avatar";
import { Site as SiteResource } from "@/resources/forge";
import draggable from "vuedraggable";
import {
  MODAL_CREATE_PROJECT,
  MODAL_CREATE_SITE,
  MODAL_CREATE_ORGANISATION,
  MODAL_ALERT,
} from "@/components/Modals";
import NavigationSites from "@/components/TheNavigation/NavigationSites";

export default {
  name: "NavigationClientList",
  components: { Avatar, draggable, NavigationSites },
  data() {
    let activeOrg = this.$store.state.organisations.current
      ? this.$store.state.organisations.current.id
      : 0;
    return {
      activeOrganisation: activeOrg,
      listGroups: {},
      projectsArray: [],
      sitesArray: [],
    };
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.current,
      projects: ({ projects }) => projects.list,
      sites: ({ sites }) => sites.list,
      organisations: ({ organisations }) => organisations.list,
      isNavDrawerMiniVariant: ({ application }) =>
        application.isNavDrawerMiniVariant,
    }),
    selectedSite() {
      if (this.$route.path.includes("/site/")) return this.$route.params.id;
    },
    sitesUnassigned() {
      return this.productionSites.filter((s) => !s.projectId);
    },
    forgeSites() {
      return this.sitesUnassigned.filter((s) => s.kind == "site");
    },
    forgePlugins() {
      return this.sitesUnassigned.filter((s) => s.kind == "plugin");
    },
    objMaxSites() {
      if (this.$store.state.organisations.current.id == 0)
        return this.user.maximum_number_of_sites;
      else return this.$store.state.organisations.current.maximum_sites;
    },
    objSites() {
      if (this.$store.state.organisations.current.id == 0)
        return this.user.sites_count;
      else return this.$store.state.organisations.current.sites_count;
    },
    productionSites() {
      return this.sites.filter((s) => s.mode == "production");
    },
    resourceTypes() {
      return [
        { type: "sites", text: "Sites" },
        { type: "plugins", text: "Plugins" },
      ];
    },
    orgSettingsAvailable() {
      return this.$store.state.organisations.current.id != 0;
    },
    organisationId: {
      get() {
        return this.$store.state.organisations.current
          ? this.$store.state.organisations.current.id
          : null;
      },
    },
    avatar() {
      if (this.user.avatar) return this.user.avatar;
      return require("@/assets/avatar-placeholder.svg");
    },
    siteLimitReached() {
      if (this.$store.state.organisations.current.id == 0)
        return this.user.maximum_number_of_sites <= this.productionSites.length;
      else
        return (
          this.$store.state.organisations.current.maximum_sites <=
          this.$store.state.organisations.current.sites_count
        );
    },
  },
  mounted() {
    this.projectsArray = _.cloneDeep(this.$store.state.projects.list);
    this.sitesArray = this.filteredSitesArray(
      _.cloneDeep(this.$store.state.sites.list)
    );
  },
  watch: {
    "$route.params.id"() {
      if (this.$route.matched[0].name == "site" && this.activeSite)
        this.$set(this.listGroups, this.activeSite.projectId, true);
      if (this.$route.matched[0].name == "project" && this.activeProject)
        this.$set(this.listGroups, this.activeProject.id, true);
    },
    "$store.state.sites.list": {
      handler(newVal, oldVal) {
        this.sitesArray = this.filteredSitesArray(_.cloneDeep(newVal));
        this.loading = false;
      },
      deep: true,
    },
    "$store.state.projects.list": {
      handler(newVal, oldVal) {
        this.projectsArray = _.cloneDeep(newVal);
        this.sitesArray = this.filteredSitesArray(
          _.cloneDeep(this.$store.state.sites.list)
        );
        this.loading = false;
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("application", ["toggleisNavDrawerMiniVariant"]),
    ...mapActions("application", ["logOut"]),
    isProjectActive(project) {
      if (!this.$route.matched[0]) return false;
      if (this.$route.matched[0].name == "site")
        return this.activeSite.projectId == project.id;
      if (this.$route.matched[0].name == "project")
        return this.activeProject.id == project.id;
    },
    openProject(project) {
      this.$router.push("/project/" + project.id);
    },
    moveToSite(site) {
      this.$router.push("/site/" + site.id);
    },
    handleChangePosition(event, list, project) {
      if (event.added) {
        let { newIndex, element } = event.added;
        let validated = true;
        let projectOwner = 0;
        if (this.$store.state.organisations.current.id == 0 && project)
          projectOwner = project.user_id;
        else if (this.$store.state.organisations.current.id != 0)
          projectOwner = this.$store.state.organisations.current.user_id;
        let validatedMessage =
          "You should be the owner of a site and a project";
        let validatedChangeOwner = false;
        if (!project && !element) validated = false;
        else if (
          project &&
          (projectOwner != this.user.id || element.userId != this.user.id)
        )
          validated = false;
        else if (!project && this.user.id != element.userId) validated = false;
        if (
          project &&
          element &&
          projectOwner != this.user.id &&
          element.userId == this.user.id
        ) {
          validatedChangeOwner = true;
          validated = true;
        }
        if (validated && !validatedChangeOwner) {
          const moveToId = project ? project.id : null;
          let moveToName = project
            ? " to " + project.name
            : " out from project";
          this.$store.commit("application/openModal", {
            component: MODAL_ALERT,
            props: {
              title: "Site Move",
              text: "Are you want to move " + element.url + moveToName + " ?",
              actionBtnLabel: "Move",
              showCancelBtn: true,
              action: () => {
                this.$store
                  .dispatch("sites/moveSite", {
                    id: element.id,
                    project_id: moveToId,
                  })
                  .then(() => {
                    return true;
                  });
              },
            },
          });
        } else if (validated && validatedChangeOwner) {
          let app_url = location.protocol + "//" + location.host;
          let success = null;
          let params = {
            new_owner_id: project.user_id,
            app_url: app_url,
            project_id: project.id,
          };
          this.$store.commit("application/openModal", {
            component: MODAL_ALERT,
            props: {
              title: "Change site owner to project owner",
              text: "Are you sure? To project owner will be send an email to confirm change.",
              actionBtnLabel: "Change",
              showCancelBtn: true,
              action: () => {
                SiteResource.changeSiteOwner({ id: element.id }, params).catch(
                  (e) => {
                    setTimeout(() => {
                      this.changeOwnerMessage = e.body.message;
                      this.changeOwnerFailed = true;
                    }, 1800);
                  }
                );
              },
            },
          });
        } else {
          this.$store.commit("application/openModal", {
            component: MODAL_ALERT,
            props: {
              title: "Site Move",
              text: validatedMessage,
              actionBtnLabel: "Close",
            },
          });
        }
      }

      this.projectsArray = _.cloneDeep(this.$store.state.projects.list);
      this.sitesArray = this.filteredSitesArray(
        _.cloneDeep(this.$store.state.sites.list)
      );
    },
    filteredObjects(sites) {
      sites = sites.filter((site) => site !== undefined);
      let prodSites = sites.filter((s) => s.mode == "production");
      prodSites = _.uniqBy(prodSites, "id");
      const itemsOnTop = prodSites.filter((site) => site.kind == "site");
      const itemsOnBottom = prodSites.filter((site) => site.kind == "plugin");
      return [...itemsOnTop, ...itemsOnBottom];
    },
    filteredSitesArray(sites) {
      sites = sites.filter((s) => s.mode == "production" && !s.projectId);
      const itemsOnTop = sites.filter((site) => site.kind == "site");
      const itemsOnBottom = sites.filter((site) => site.kind == "plugin");
      return [...itemsOnTop, ...itemsOnBottom];
    },
    initials(username) {
      username[0].toUpperCase();
    },
    openSiteUrl(site) {
      window.open("http://" + site.url, "_blank");
    },

    createOrganisation() {
      this.$store.commit("application/openModal", {
        component: MODAL_CREATE_ORGANISATION,
      });
    },

    async setActiveOrganisation(id) {
      if (id == -1) {
        this.createOrganisation();
        this.activeOrganisation = this.organisations.find(
          (org) => org.id == this.$store.state.organisations.current.id
        );
      } else {
        await this.$store.dispatch("organisations/setCurrent", id, {
          root: true,
        });
        location = "/";
      }
    },
    createSite(project_id = null) {
      this.$store.commit("application/openModal", {
        component: MODAL_CREATE_SITE,
        props: {
          projectId: project_id,
        },
      });
    },
    openOrganisationSettings() {
      this.$router.push(`/organisation/${this.organisationId}`);
    },
    createSite(project_id = null) {
      this.$store.commit("application/openModal", {
        component: MODAL_CREATE_SITE,
        props: {
          projectId: project_id,
        },
      });
    },
    createProject() {
      this.$store.commit("application/openModal", {
        component: MODAL_CREATE_PROJECT,
      });
    },
  },
};
</script>
<style lang="scss">
$style: the-navigation;

.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: unset !important;
}

.v-list-item--active .org_select {
  width: 100%;
}

.v-list-item__icon {
  margin-right: 5px !important;
}
.v-list-group--sub-group .v-list-group__header {
  padding-left: 0px !important;
}

.#{$style} {
  padding: 82px 24px 191px;
  &.v-navigation-drawer--mini-variant {
    overflow: initial;
  }
  &__img_logo {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
  }
  &__item_name {
    margin-left: 10px;
    display: inline-block;
  }
  &__project-icon.v-list-item__icon {
    margin-right: 15px !important;
  }
  &__project_title {
    overflow: hidden;
    min-width: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  &.v-navigation-drawer {
    background-color: $dark-bg-color;
  }
  &__header {
    position: absolute;
    top: 16px;
    left: 6px;
    right: 0;
  }
  &__icon {
    cursor: pointer;
    transition: filter 0.2s ease;
    &:hover {
      filter: brightness(1.2);
    }
  }
  &__toggle-btn.v-icon {
    position: absolute;
    right: 22px;
    top: 13px;
    border: 1px solid $N5;
    border-radius: 4px;
    z-index: 1;
    background-color: $dark-bg-color;
    &._mini {
      right: -10px;
    }
    &:focus::after {
      content: none;
    }
    &:hover {
      border-color: $N3;
    }
  }
  &__list {
    padding: 0;
  }
  &__divider {
    margin: 0 0 24px;
    &.v-divider {
      background-color: $N5;
    }
  }
  &__list-item {
    margin-bottom: 20px;
  }
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 24px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    &._small {
      padding: 0 28px 40px;
    }
  }
  &__footer-btn {
    &.v-btn {
      margin-bottom: 20px;
      padding: 0 !important;
    }
  }
  &__footer-link {
    &.v-size--default {
      font-size: 16px;
      padding: 0 !important;
    }
    font-weight: 600;
  }
  &__footer-icon {
    margin-right: 14px;
    &._margin-less {
      margin-right: 0;
    }
  }
  &__nav-icon {
    fill: $N2;
  }
  &__nav-content {
    color: $N2;
    font-weight: 500;
    font-size: $H14;
    white-space: nowrap;
  }
  &__list-item {
    &._active {
      color: $white;
      .#{$style}__nav-icon {
        fill: $B1;
      }
      .#{$style}__nav-content {
        color: $B1;
      }
      &:before {
        content: none;
      }
    }
  }
  &__projects {
    &._small {
      padding: 0;
    }
    &
      .v-list-group__header
      .v-list-item__icon.v-list-group__header__append-icon {
      display: none;
    }
    & .#{$style}__head {
      color: $N2 !important;
      .v-icon {
        color: inherit;
      }
    }
    &._active {
      & .#{$style}__head {
        color: $white !important;
      }
    }
    & .v-list-item {
      padding: 0;
    }
  }
  &__project {
    cursor: pointer;
    padding-left: 30px;
    min-height: 40px;
    padding-top: 9px;

    .#{$style}__sub-arrow {
      float: right;
      margin-right: 20px;
    }
    .#{$style}__sub-list-item-name {
      font-weight: 500;
      font-size: 0.8125rem;
      color: $N2;
    }
    &._active {
      .#{$style}__sub-list-item-name {
        color: white;
      }
    }
    &:hover {
      background: #2a293a;
      .#{$style}__sub-list-item-name {
        color: white;
      }
      .#{$style}__sub-arrow {
        display: inline-flex;
      }
    }
    &.v-list-item {
      padding: 0 8px;
    }
    &.theme--dark.v-list-item:not(.v-list-item--active):not(
        .v-list-item--disabled
      ) {
      color: $N2;
    }
    &.v-list-item--active {
      color: $white;
      & .#{$style}__sub-list-item-name {
        color: $white;
      }
    }

    &_sites_list {
      display: inline-block;
    }
  }
  &__sub-list-name {
    &.v-list-item {
      padding-left: 0 8px;
    }
  }
  &__sub-list-item-name {
    font-weight: 300;
    font-size: $H14;
    color: $N2;
  }
  &__sub-arrow {
    display: none;
    &.theme--dark.v-btn.v-btn--icon {
      color: inherit;
    }
  }
  &__dot {
    display: inline-block !important;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $G1;
    margin-right: 15px;
  }

  &__head {
    .the-navigation__add_site_to_project_icon {
      display: none;
    }
    &:hover {
      .the-navigation__add_site_to_project_icon {
        display: inline-flex;
      }
    }
  }
}
.navigation-sites__list-item {
  &:hover {
    .#{$style}__sub-arrow {
      display: inline-flex;
      float: right;
      margin-right: 20px;
    }
  }
}

$style2: navigation-sites;
.#{$style2} {
  padding-left: 4px;
  &._small {
    padding-left: 0;
    .#{$style2}__plugin_img {
      margin-left: 17px !important;
    }
  }
  &__nav-icon {
    color: $N2;
  }
  .__plugin {
    padding-left: 5px;
  }
  &__plugin_img {
    width: 16px;
    height: 16px;
    margin-left: 7px;
    margin-right: 7px;
  }
  &__dot {
    display: inline-block !important;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $G1;
    margin-right: 15px;
  }
  &__nav-content {
    display: inline-block;
    color: $N2;
    font-weight: 500;
    font-size: 0.8125rem;
    white-space: nowrap;
    letter-spacing: normal;
  }
  &__list-item {
    cursor: pointer;
    padding-left: 20px;
    min-height: 40px;
    padding-top: 9px;
    &:hover {
      background: #2a293a;
      .#{$style2}__nav-content {
        color: white;
      }
    }
    &._active {
      color: $white;
      .#{$style2}__nav-icon {
        color: $white;
      }
      .#{$style2}__nav-content {
        color: $white;
      }
      &:before {
        content: none;
      }
    }
  }
}
</style>
