<template>
  <div class="ModalCardData">
    <div class="ModalCardData__head" v-if="paid">
      <span class="ModalCardData__label"> Choose you country: </span>
      <v-select
        dark
        dense
        filled
        class="ModalCardData__select"
        v-model="selectedCountry"
        :items="countries"
      >
      </v-select>
    </div>
    <div class="ModalCardData__inputs">
      <div class="ModalCardData__col">
        <span class="ModalCardData__label">Card Number</span>
        <div id="card-number-element" class="ModalCardData__input" dark />
      </div>
      <div class="ModalCardData__col">
        <span class="ModalCardData__label">Expiration (MM/YYYY)</span>
        <div id="card-expiry-element" class="ModalCardData__input" />
      </div>
      <div class="ModalCardData__col _small">
        <span class="ModalCardData__label">CVC</span>
        <div id="card-cvc-element" class="ModalCardData__input"></div>
      </div>
    </div>
    <div v-if="paid" class="ModalCardData__footer">
      <v-btn @click="cancel" color="n5" dark>
        Cancel
      </v-btn>
      <v-btn @click="changeCard" color="y1" dark>
        Change Card
      </v-btn>
    </div>
  </div>
</template>

<script>
import { STRIPE_PUBLIC_KEY } from "@/env";
import { COUNTRIES } from "@/countries";
export default {
  name: "ModalCardData",
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  props: {
    valueFields: {
      type: Object,
      default: () => {
        return {
          stripe: null,
          cardNumberElement: null,
          cardExpiryElement: null,
          cardCvcElement: null,
        };
      },
    },
    inputFields: {
      type: Object,
      default: () => {
        return {
          cardNumber: "v-card-number",
          cardName: "v-card-name",
          cardMonth: "v-card-month",
          cardYear: "v-card-year",
          cardCvv: "v-card-cvv",
        };
      },
    },
    labels: {
      type: Object,
      default: () => {
        return {
          cardName: "Full Name",
          cardHolder: "Card Holder",
          cardMonth: "MM",
          cardYear: "YY",
          cardExpires: "Expires",
          cardCvv: "CVV",
        };
      },
    },
    isCardNumberMasked: {
      type: Boolean,
      default: true,
    },
    paid: {
      type: Boolean,
      default: false,
    },
    hasRandomBackgrounds: {
      type: Boolean,
      default: true,
    },
    backgroundImage: {
      type: [String, Number],
      default: "",
    },
    baseCountry: {
      type: String,
      default: "",
    },
  },
  data: (instance) => ({
    minCardYear: new Date().getFullYear(),
    mainCardNumber: "",
    cardNumberMaxLength: 19,
    countries: COUNTRIES,
    selectedCountry: instance.baseCountry,
  }),
  computed: {
    minCardMonth() {
      if (this.valueFields.cardYear === this.minCardYear)
        return new Date().getMonth() + 1;
      return 1;
    },
  },
  mounted() {
    let style = {
      base: {
        color: "#fff",
        fontSize: "12px",
        border: "1px solid red",
        "::placeholder": {
          color: "#a4a4ae",
        },
      },
      invalid: {
        color: "#e94a53",
      },
      complete: {
        color: "#6bc77b",
      },
    };
    // eslint-disable-next-line no-undef
    this.valueFields.stripe = Stripe(STRIPE_PUBLIC_KEY);
    const elements = this.valueFields.stripe.elements();

    this.valueFields.cardNumberElement = elements.create("cardNumber", {
      style,
    });
    this.valueFields.cardNumberElement.mount("#card-number-element");

    this.valueFields.cardExpiryElement = elements.create("cardExpiry", {
      style,
    });
    this.valueFields.cardExpiryElement.mount("#card-expiry-element");

    this.valueFields.cardCvcElement = elements.create("cardCvc", { style });
    this.valueFields.cardCvcElement.mount("#card-cvc-element");
    this.$emit("input", this.valueFields);
  },
  watch: {
    cardYear() {
      if (this.valueFields.cardMonth < this.minCardMonth) {
        this.valueFields.cardMonth = "";
      }
    },
  },
  methods: {
    changeNumber(e) {
      const value = this.valueFields.cardNumber.replace(/\D/g, "");
      if (/^3[47]\d{0,13}$/.test(value)) {
        this.valueFields.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 17;
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        this.valueFields.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 16;
      } else if (/^62[0-9]\d*/.test(value)) {
        this.valueFields.cardNumber = value
          .replace(/(\d{6})/, "$1 ")
          .replace(/(\d{6}) (\d{7})/, "$1 $2 ")
          .replace(/(\d{6}) (\d{7}) (\d{6})/, "$1 $2 $3 ")
          .replace(/(\d{5}) (\d{5}) (\d{5}) (\d{4})/, "$1 $2 $3 $4");
        this.cardNumberMaxLength = 21;
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        this.valueFields.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
          .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
        this.cardNumberMaxLength = 19;
      }

      if (e.inputType == "deleteContentBackward") {
        const lastChar = this.valueFields.cardNumber.substring(
          this.valueFields.cardNumber.length,
          this.valueFields.cardNumber.length - 1
        );

        if (lastChar == " ") {
          this.valueFields.cardNumber = this.valueFields.cardNumber.substring(
            0,
            this.valueFields.cardNumber.length - 1
          );
        }
      }
      this.$emit("input", this.valueFields);
    },
    changeSimpleData() {
      this.$emit("input", this.valueFields);
    },
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n;
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked;
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      } else {
        this.unMaskCardNumber();
      }
    },
    maskCardNumber() {
      this.valueFields.cardNumberNotMask = this.valueFields.cardNumber;
      this.mainCardNumber = this.valueFields.cardNumber;
      const arr = this.valueFields.cardNumber.split("");
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== "") {
          arr[index] = "*";
        }
      });
      this.valueFields.cardNumber = arr.join("");
    },
    unMaskCardNumber() {
      this.valueFields.cardNumber = this.mainCardNumber;
    },
    cancel() {
      this.$emit("close");
    },
    changeCard() {
      this.valueFields.stripe
        .createToken(this.valueFields.cardNumberElement)
        .then((res) => {
          let params = {
            id: this.$store.state.user.current.id,
            session: {
              stripe_card_token: res.token.id,
              plan_id: this.selectedPlan,
              country: this.selectedCountry,
            },
          };
          this.$store.dispatch("user/updateSubscription", params).then(() => {
            this.$emit("close");
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss">
$style: ModalCardData;
.#{$style} {
  @extend %modal-body-styles;
  min-width: 600px;
  padding: 24px;
  &__inputs {
    display: flex;
    margin: 0 -8px;
  }
  &__label {
    @extend %label-styles;
  }
  &__input {
    @extend %input-styles;
    padding:  8px 12px;
  }
  &__col {
    padding: 8px;
    flex: 1 1 calc(100% / 3);
    &._small {
      flex-basis: 20%;
    }
  }
  &__footer {
    padding: 16px 0 0;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
.subscription-container {
  background: #1f1e31;
  background-color: #1f1e31 !important;
  border: 1px solid #2e2c42;
  border-color: #2e2c42 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}
</style>
