<template>
  <v-card class="ModalCreateSite" max-width="600px">
    <h4 class="ModalCreateSite__title">
      Create new site
    </h4>
    <div v-if="!saving">
      <span class="ModalCreateSite__label">Choose a subdomain</span>
        <v-text-field
          v-model="url"
          class="ModalCreateSite__input"
          autofocus
          hide-details
          filled
          dense
        />
        <div v-if="errorMain" class="error-main mt-4 pa-4">
          {{ errorMain }}
        </div>
        <div class="ModalCreateSite__footer">
          <v-btn @click="close" color="n5" class="">Cancel</v-btn>
          <v-btn @click="onCreate" color="y1">Create</v-btn>
        </div>
    </div>
    <div v-else class="spinner">
      <IntersectingCirclesSpinner :animation-duration="1200" :size="100" :color="'#496DDB'" />
    </div>
  </v-card>
</template>

<script>
import {IntersectingCirclesSpinner} from "epic-spinners";
import {generateDomain} from '@/utils/common';

export default {
    name: "ModalCreateSite",
    components: {IntersectingCirclesSpinner},
    props: {
      projectId: {
        type: Number,
        default: null
      }
    },
    data() {
      let isOrg = this.$store.state.organisations.current.id != 0;
      return {
        url: '',
        errorMain: "",
        saving: false,
        isOrganisation: isOrg
      }
    },
    created() {
      let plan_id = !this.isOrganisation ? 
        this.$store.state.user.current.plan_id : 
        this.$store.state.organisations.current.plan_id;
      this.url = generateDomain(plan_id);
    },
    methods: {
      onCreate() {
        if (!this.url)
          return;
        this.saving = true;
        const productionSites = this.$store.state.sites.list.filter((s) => s.mode == 'production');
        let maxSites = this.isOrganisation ? 
          this.$store.state.organisations.current.maximum_sites :
          this.$store.state.user.current.maximum_number_of_sites;
        if (maxSites <= productionSites.length)
        {
          this.errorMain = "The maximum number of sites has been reached";
          return;
        }
        this.errorMain = "";
        const siteData = {
          url: this.url
        };
        if (this.projectId != null) 
          siteData["project_id"] = this.projectId;
        this.$store.dispatch('sites/create', siteData)
        .then(res => {
          this.errorMain = "";
          this.$emit('close');
          this.$store.dispatch('sites/setActive', res.id);
          this.$router.push("/site/" + res.id);
        })
        .catch(error => {
          console.log(error);
          if (!error)
            return;
          let errorMain = '';
          error.body.errors.forEach(message => {
            errorMain = errorMain + message + '. ';
          });
          this.errorMain = errorMain;
          this.saving = false;
        });
        
      },
      close() {
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss">
$style: ModalCreateSite;
.#{$style} {
  &.theme--dark.v-card {
    @extend %modal-body-styles;
    min-width: 600px;
    padding: 24px 24px 0;
  }
  &__title {
    font-weight: 500;
    font-size: $H16;
    color: $white;
    margin-bottom: 32px;
  }
  &__label {
    @extend %label-styles;
  }
  &__input {
    &.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
      @extend %input-styles;
    }
    &.v-input input {
      color: $white;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    gap: 16px;
    & > .theme--dark.v-btn {
      color: $white;
    }
  }
  .error-main {
    background: #f9d1d1;
    border-radius: 2px;
  }
  
  .spinner {
    display: flex;
    width: 100%;
    min-width: 150px;
    height: 100%;
    align-items: center;
    margin-bottom: 15px;
    justify-content: center;
  }
}
</style>
