import Vue from "vue";
import Vuetify from "vuetify";
import { mapState } from "vuex";
import "vuetify/dist/vuetify.min.css";
import "@/assets/styles.scss";
import VueFormGenerator from 'vue-form-generator'
import Multiselect from 'vue-multiselect'
import 'vue-form-generator/dist/vfg.css'
import { FieldArray } from 'vfg-field-array';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import App from "@/App.vue";

import store from "@/store";
import router from "@/router";
import "@/resources";
import Icon from "./components/_Common/Icon";

import { PUSHER_KEY, GOOGLE_CLIENT_ID } from "@/env";
import GAuth from 'vue-google-oauth2';

import DisableAutocomplete from '@aacassandra/vue-disable-autocomplete';

import FieldArrayAccordionItem from "./components/FormBuilder/FieldArrayAccordionItem";
import FieldCustomObject from "./components/FormBuilder/FieldCustomObject";
import FieldSelectReset from "./components/FormBuilder/FieldSelectReset";
import FieldBooleanSwitch from "./components/FormBuilder/FieldBooleanSwitch";
import FieldEditor from "./components/FormBuilder/FieldEditor";
import FieldDragDropUploader from "./components/ThePlugin/PublishFlow/Fields/FieldDragDropUploader";
import FieldIconUpload from "./components/ThePlugin/PublishFlow/Fields/FieldIconUpload";

Vue.config.productionTip = false;
Vue.use(Vuetify);

Vue.use(DisableAutocomplete);
Vue.use(Element);
Vue.use(VueViewer)

const gauthOption = {
  clientId: GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)


Vue.use(require("vue-pusher"), {
  api_key: PUSHER_KEY,
});

const vuetify = new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#496DDB",
        secondary: "#424242",
        accent: "#50881f",
        error: "#C95D63",
        info: "#2196F3",
        success: "#6eccb9",
        warning: "#FFC107",
        y1: "#FF7E21",
        n2: "#A4A4AE",
        n3: "#5e636d",
        n5: "#2E2C42",
        g1: "#6BC77B",
        r1: "#E94A53",
        e1: "#FA646C",
      },
    },
  },
});

Vue.component("TheIcon", Icon);
Vue.component('Multiselect', Multiselect);
Vue.component('FieldArray', FieldArray);
Vue.component('FieldArrayAccordionItem', FieldArrayAccordionItem);
Vue.component('FieldCustomObject', FieldCustomObject);
Vue.component('FieldSelectReset', FieldSelectReset);
Vue.component('FieldDragDropUploader', FieldDragDropUploader);
Vue.component('FieldIconUploader', FieldIconUpload);
Vue.component('FieldBooleanSwitch', FieldBooleanSwitch);
Vue.component('FieldEditor', FieldEditor);
Vue.component('VueFormGenerator', VueFormGenerator.component);

Vue.mixin({
  computed: {
    ...mapState({
      activeProject: (state) => state.projects.active,
      activeSite: (state) => state.sites.active,
    }),
  },
});

const app = new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export default app;
